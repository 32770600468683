import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import type { Meta } from "@/__main__/router.mjs";
import {
  GAME_SHORT_NAMES,
  GAME_SYMBOL_BY_SHORT_NAME,
} from "@/app/constants.mjs";
import ArticleList from "@/marketing/ArticleList.jsx";
import {
  Section,
  SectionTitle,
} from "@/marketing/ContentfulArticles.style.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Maps() {
  const { t } = useTranslation();
  const {
    contentful: { related },
  } = useSnapshot(readState);

  const shortName = GAME_SHORT_NAMES[GAME_SYMBOL_BY_SHORT_NAME.MarvelRivals];

  const maps = useMemo(
    () =>
      related.filter((i) =>
        ["marvelRivals", "map"].every((t) => i.tags.find((j) => j === t)),
      ),
    [related],
  );

  return (
    <Section>
      <header>
        <SectionTitle>
          {t("marvelrivals:title.marvelRivalsMaps", "Marvel Rivals Maps")}
        </SectionTitle>
      </header>
      <ArticleList
        articles={maps.map((i) => ({
          id: i.id,
          coverImageUrl: i.imageUrl,
          title: i.title,
          excerpt: i.excerpt,
          createdAt: i.createdAt,
          link: `/${shortName}/article/${i.id}`,
        }))}
        count={9}
      />
    </Section>
  );
}

export function meta(): Meta {
  return {
    title: [
      "marvelrivals:meta.maps.title",
      "Marvel Rivals Maps Guide: Best Tips, Tricks & Strategies - Blitz",
    ],
    description: [
      "marvelrivals:meta.maps.description",
      "Master every map in Marvel Rivals with our in-depth guides, strategies, and pro tips. Learn the best hero placements, objectives, hidden routes, and winning tactics to dominate the battlefield. Stay ahead with the latest map insights and expert tricks for every location in the game.",
    ],
  };
}
